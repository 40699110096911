import { render, staticRenderFns } from "./payFail.vue?vue&type=template&id=63932762&scoped=true"
import script from "./payFail.vue?vue&type=script&lang=js"
export * from "./payFail.vue?vue&type=script&lang=js"
import style0 from "./payFail.vue?vue&type=style&index=0&id=63932762&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63932762",
  null
  
)

export default component.exports