<template>
  <div class="app">
    <div class="order-center">
      <div class="order-logo">
        <img src="../../assets/images/order_logo_fail.png" alt="">
      </div>
      <div class="fail-words">付款失败</div>
      <div class="check-orders">
        <Button class="login_buttonBg button-words"
                style="width: 320px;height: 57px;margin-top: 40px;"
                type="primary">
          重新支付
        </Button>
        <Button class="order-button"
                style="width: 320px;height: 57px;margin-top: 40px; margin-left: 10px;font-size:24px;
                      font-weight:800;line-height:36px;"
                type="success" ghost>
          查看订单
        </Button>
      </div>
    </div>
    <div class="leave-message-words">
      如有任何问题，请在下方留言，我们尽快为您处理
    </div>
    <div class="leave-messages">
      <Input type="textarea" v-model="leaveMessage" :rows="10" />
    </div>
    <div class="submit-message">
      <Button class="login_buttonBg button-words"
              style="width: 320px;height: 57px;margin-top: 20px;font-size:24px;
                      font-weight:800;line-height:36px;"
              type="primary">提交</Button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "successOrder"
  }
</script>

<style scoped lang="less">
  .order-center {
    width: 740px;
    height: 429px;
    margin-top: 355px;
    margin-left: 648px;
    transform: translateY(-50%);

    .order-logo {
      margin-left: 290px;
      transform: translateY(40%);
    }

    .fail-words {
      margin-top: 90px;
      margin-left: 305px;
      font-size: 32px;
      font-weight: 800;
      color: rgba(67, 67, 67, 1);
      line-height: 48px;
    }

    .check-orders {
      margin-left: 40px;

      .button-words {
        font-size: 24px;
        font-weight: 800;
        color: rgba(255, 255, 255, 1);
        line-height: 36px;
      }

      .order-button {
        border-radius: 50px;
        background-repeat: repeat-x;
      }
    }
  }

  .leave-message-words {
    margin-top: -180px;
    margin-left: 750px;
    font-size:24px;
    font-weight:400;
    color:rgba(67,67,67,1);
    line-height:36px;
  }

  .leave-messages {
    margin-left: 648px;
    margin-top: 20px;
    width: 740px;
    height: 180px;
  }

  .submit-message {
    margin-left: 41%;
    margin-top: 40px;
  }
</style>
